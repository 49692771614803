<template>
  <SiteTemplateV2 v-if="clubVersion === 'v2'">
    <CertificatesComponentV2 />
  </SiteTemplateV2>

  <SiteTemplate v-else>
    <div class="containerx">
      <div class="row">
        <CertificatesComponent />
      </div>
    </div>
  </SiteTemplate>
</template>

<script>
import Cookies from "js-cookie";
import SiteTemplate from "@/templates/SiteTemplate.vue";
import CertificatesComponent from "@/components/CertificatesComponent.vue";
import SiteTemplateV2 from "@/templates/SiteTemplateV2.vue";
import CertificatesComponentV2 from "@/components/V2/CertificatesComponent.vue";

export default {
  components: {
    SiteTemplate,
    CertificatesComponent,
    SiteTemplateV2,
    CertificatesComponentV2,
  },
  computed: {
    theme() {
      return this.$store.state.globalSettings.theme;
    },
    clubVersion() {
      return this.$store.state.globalSettings.clubVersion;
    },
  },
  methods: {
    getTheme() {
      const d = document;
      var theme = Cookies.get("themeClub");
      if (theme === undefined || theme === null) {
        this.themeDark = false;
      } else {
        d.documentElement.getAttribute("data-theme");
        if (theme === "dark") {
          this.themeDark = true;
        } else {
          this.themeDark = false;
        }
      }
    },
  },
};
</script>

<style scoped>
.containerx {
  width: 100%;
}
</style>
