<template>
  <b-modal
    id="modal-new-certify-V2"
    hide-header
    hide-footer
    content-class="border-radius modal-content"
  >
    <slot name="modal-header-close">
      <div class="close" @click="hideModal">×</div>
    </slot>
    <slot name="modal-title">
      <p class="text1">
        {{ $t("certificates_component.modal_congratulations") }} 🎉
      </p>
    </slot>
    <slot name="default">
      <div class="allContentModal">
        <div class="spaceText2">
          <p class="fade-one text2">
            <b class="checklist">✓</b
            >{{ $t("certificates_component.modal_text1") }}
          </p>
          <p class="fade-two text2">
            <b class="checklist">✓</b
            >{{ $t("certificates_component.modal_text2") }}
          </p>
          <p class="fade-three text2">
            <b class="checklist">✓</b
            >{{ $t("certificates_component.modal_text3") }}
          </p>
          <p class="fade-four text2">
            <b class="checklist">✓</b
            >{{ $t("certificates_component.modal_text4") }}
          </p>
        </div>
        <div>
          <button class="fade-five button disabled" @click="downloadCertify">
            {{ $t("certificates_component.modal_download") }}
          </button>
        </div>
      </div>
    </slot>
  </b-modal>
</template>
<script>
import CertifyService from "@/services/resources/CertifyService";
const serviceCertificate = CertifyService.build();
import axios from "axios";
import Cookies from "js-cookie";

export default {
  components: {},
  data() {
    return {
      nameCourse: "",
      idCertify: null,
      notNew: false,
      concluidoCourse: "",
    };
  },
  computed: {
    isMobile() {
      return this.client.width <= 576;
    },
    theme() {
      return this.$store.state.globalSettings.theme;
    },
    clubVersion() {
      return this.$store.state.globalSettings.clubVersion;
    },
  },
  methods: {
    hideModal() {
      this.$root.$emit("bv::hide::modal", "modal-new-certify-V2", "#btnShow");
    },
    getCertify() {
      serviceCertificate.read("/notification").then((resp) => {
        //console.log("get certify recent", resp);
        if (resp.length > 0) {
          this.$root.$emit(
            "bv::show::modal",
            "modal-new-certify-V2",
            "#btnShow"
          );
          this.nameCourse = resp[0].title;
          this.idCertify = resp[0].id;
          this.concluidoCourse = resp[0].created_at;
          this.notNew = false;
        }
      });
    },
    downloadCertify() {
      axios({
        url:
          process.env.VUE_APP_API_HOST +
          `/certificate/download/${this.idCertify}`,
        method: "GET",
        responseType: "blob",
        headers: {
          Authorization: Cookies.get("auth_greennCourse"),
        },
      }).then((response) => {
        // var fileURL = window.URL.createObjectURL(new Blob([response.data], {type: "application/pdf"}));
        var fileURL = window.URL.createObjectURL(
          new Blob([response.data], { type: "image/jpeg" })
        );
        const fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", `Certificado-${this.nameCourse}`);
        document.body.appendChild(fileLink);
        fileLink.click();
      });
    },
  },
  mounted() {
    this.getCertify();
    this.$root.$on("openCertifyToModalV2", (data) => {
      //console.log('openCertifyToModal', data);
      this.$root.$emit("bv::show::modal", "modal-new-certify-V2", "#btnShow");
      this.nameCourse = data.title;
      this.idCertify = data.id;
      this.concluidoCourse = data.created_at;
      this.notNew = true;
    });
  },
};
</script>
<style lang="scss">
.border-radius {
  border-radius: 30px;
}
.modal-content {
  background-color: var(--background-v2) !important;
}

#modal-new-certify-V2 {
  z-index: 99999999999;
  .modal-header {
    border: none;
  }
}
</style>
<style lang="scss" scoped>
.close {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  text-align: center;
  font-size: 40px;
  opacity: 1;
  text-shadow: inherit;
  font-weight: lighter;
  margin-top: 9px;
  cursor: pointer;
  color: var(--fontcolor-v2);
  background: var(--background2-v2);
}

.checklist {
  color: var(--basecolor-v2);
  margin-right: 13px;
}
.fade-one {
  animation: fadeIn 1s;
}
.fade-two {
  animation: fadeIn 5s;
}
.fade-three {
  animation: fadeIn 9s;
}
.fade-four {
  animation: fadeIn 13s;
}

.fade-five {
  animation: fadeIn 17s;
}

@keyframes fadeIn {
  0% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}

#modal-new-certify-V2 {
  .allContentModal {
    padding: 0px 10px;
    margin: 0 auto;
    display: block;
    border-radius: 30px;
    text-align: -webkit-left;
  }
  .spaceText2 {
    margin-bottom: 20px;
  }
  .spaceText1 {
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .text1 {
    font-family: "Montserrat Alternates";
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    letter-spacing: 0.2px;
    text-align: left;
    margin-top: 20px;
    margin-bottom: 40px;
    color: var(--fontcolor-v2);
  }
  .text2 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    text-align: left;
    letter-spacing: 0.2px;
    font-size: 17px;
    color: var(--fontcolor-v2);
  }

  .button {
    width: 100%;
    height: 55px;
    border-radius: 30px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.2px;

    border: none;
    margin-top: 30px;

    background: var(--basecolor-v2);
    color: var(--fontcolor3-v2);
  }
}
</style>
