<template>
  <div :class="{ spaceCertify: !isMobile, spaceCertifyMobile: isMobile }">
    <b-modal ref="my-modal-certify" id="unicCertify" size="xl" hide-footer>
      <div class="spaceTitleDown">
        <p>{{ $t('certificates_component.title') }}</p>
      </div>
      <div class="spaceImgDown">
        <img
          :src="selectedCertify.media.cdn_url"
          :alt="selectedCertify.media.title"
        />
      </div>
      <div class="spaceBtnDown">
        <button class="btnDownload" @click="downloadCertify(selectedCertify)">
          {{ $t('certificates_component.download') }}
        </button>
      </div>
    </b-modal>
    <div class="flexHeader">
      <div>
        <div class="titleAll" :class="{ 'mt-3': isMobile }">
          {{ $t('certificates_component.meus') }}
          <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.43496 22.9609H16.6119H8.43496ZM12.5234 22.9609V16.4194V22.9609ZM19.065 10.6955C19.065 8.10753 19.0609 3.81517 19.0588 2.51862C19.0588 2.30175 18.9727 2.09377 18.8193 1.94042C18.666 1.78708 18.458 1.70093 18.2411 1.70093L6.80417 1.71422C6.58775 1.71421 6.38016 1.80001 6.22688 1.95281C6.07361 2.1056 5.98716 2.31293 5.98648 2.52935C5.98648 4.09216 5.97984 9.13067 5.97984 10.6955C5.97984 13.9806 10.2216 16.4194 12.5214 16.4194C14.8211 16.4194 19.065 13.9806 19.065 10.6955ZM19.065 10.6955C21.4388 10.6955 23.1534 7.79374 23.1534 4.97169V4.154H19.065V10.6955ZM5.98188 4.154H1.89343V4.97169C1.89343 7.79374 3.60803 10.6955 5.98188 10.6955V4.154Z"
              stroke="#333333"
              stroke-width="1.8"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
    </div>

    <div v-if="!loading" data-anima="top">
      <b-row v-if="totalCertificates > 2" class="spaceProp">
        <b-row>
          <div class="flex">
            <p class="titleCategoria">{{ $t('certificates_component.recentes') }}</p>
          </div>
        </b-row>
        <b-col cols="12" md="12" class="d-flex">
          <b-col
            v-for="recentes in AllCertificatesRecentes"
            :key="recentes.id"
            cols="12"
            md="4"
            class="course"
            @click="getCertifyUniq(recentes.course_id)"
          >
            <div
              :style="{
                'background-image': 'url(' + recentes.media.cdn_url + ')',
              }"
              class="box-certifyRecente"
            ></div>
            <div class="footerCertify">
              <div class="title">{{ recentes.course.title }}</div>
              <div class="flex">
                <svg
                  class="iconCertify"
                  width="12"
                  height="18"
                  viewBox="0 0 12 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    class="a"
                    d="M5.66771 11.0664C8.36366 11.0664 10.5492 8.88087 10.5492 6.18492C10.5492 3.48897 8.36366 1.30347 5.66771 1.30347C2.97176 1.30347 0.786255 3.48897 0.786255 6.18492C0.786255 8.88087 2.97176 11.0664 5.66771 11.0664Z"
                    fill="#00E4A0"
                    fill-opacity="0.3"
                  />
                  <path
                    class="b"
                    d="M3.02475 10.2923L2.18096 16.6452L5.66771 14.5531L9.15446 16.6452L8.31067 10.2853"
                    fill="#00E4A0"
                    fill-opacity="0.3"
                  />
                  <path
                    class="c"
                    d="M3.02475 10.2923L2.18096 16.6452L5.66771 14.5531L9.15446 16.6452L8.31067 10.2853M10.5492 6.18492C10.5492 8.88087 8.36366 11.0664 5.66771 11.0664C2.97176 11.0664 0.786255 8.88087 0.786255 6.18492C0.786255 3.48897 2.97176 1.30347 5.66771 1.30347C8.36366 1.30347 10.5492 3.48897 10.5492 6.18492Z"
                    stroke="#00E4A0"
                    stroke-width="1.7"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <div class="data">
                  {{ recentes.created_at | moment("DD/MM/YYYY") }}
                </div>
              </div>
            </div>
          </b-col>
        </b-col>
      </b-row>
      <div class="lineDiv" v-if="totalCertificates > 0"></div>
      <b-row class="spaceProp" v-if="totalCertificates > 0">
        <b-row>
          <div class="flex">
            <p class="titleCategoria">{{ $t('certificates_component.todos') }}</p>
          </div>
        </b-row>
        <b-col
          cols="12"
          md="12"
          :class="{
            spaceCertifyBox: !isMobile,
            spaceCertifyBoxMobile: isMobile,
          }"
        >
          <b-col
            v-for="certificado in AllCertificates"
            :key="certificado.id"
            cols="12"
            class="itemBox course"
            @click="getCertifyUniq(certificado.course_id)"
          >
            <div
              :style="{
                'background-image': 'url(' + certificado.media.cdn_url + ')',
              }"
              class="box-certify"
            ></div>
            <div class="footerCertify">
              <div class="title">{{ certificado.course.title }}</div>
              <div class="flex">
                <svg
                  class="iconCertify"
                  width="12"
                  height="18"
                  viewBox="0 0 12 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    class="a"
                    d="M5.66771 11.0664C8.36366 11.0664 10.5492 8.88087 10.5492 6.18492C10.5492 3.48897 8.36366 1.30347 5.66771 1.30347C2.97176 1.30347 0.786255 3.48897 0.786255 6.18492C0.786255 8.88087 2.97176 11.0664 5.66771 11.0664Z"
                    fill="#00E4A0"
                    fill-opacity="0.3"
                  />
                  <path
                    class="b"
                    d="M3.02475 10.2923L2.18096 16.6452L5.66771 14.5531L9.15446 16.6452L8.31067 10.2853"
                    fill="#00E4A0"
                    fill-opacity="0.3"
                  />
                  <path
                    class="c"
                    d="M3.02475 10.2923L2.18096 16.6452L5.66771 14.5531L9.15446 16.6452L8.31067 10.2853M10.5492 6.18492C10.5492 8.88087 8.36366 11.0664 5.66771 11.0664C2.97176 11.0664 0.786255 8.88087 0.786255 6.18492C0.786255 3.48897 2.97176 1.30347 5.66771 1.30347C8.36366 1.30347 10.5492 3.48897 10.5492 6.18492Z"
                    stroke="#00E4A0"
                    stroke-width="1.7"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <div class="data">
                  {{ certificado.created_at | moment("DD/MM/YYYY") }}
                </div>
              </div>
            </div>
          </b-col>
        </b-col>
      </b-row>
      <b-row v-if="totalCertificates === 0" class="spaceProp">
        <b-row>
          <div class="flex">
            <p class="titleCategoria">{{ $t('certificates_component.nenhum') }}</p>
          </div>
        </b-row>
      </b-row>
      <b-row v-if="totalCertificates > 11">
        <div class="paginacao">
          <div class="page" @click="nextPage('prev')">{{ $t('certificates_component.anterior') }}</div>
          <div class="page" @click="nextPage('next')">{{ $t('certificates_component.proxima') }}</div>
        </div>
      </b-row>
    </div>
    <!-- Loading -->
    <div v-if="loading" class="d-flex justify-content-center mt-5">
      <b-spinner label="Loading..."></b-spinner>
    </div>
  </div>
</template>

<script>
import CourseService from "@/services/resources/CourseService"
import CertifyService from "@/services/resources/CertifyService"

const serviceCourse = CourseService.build()
const serviceCertificate = CertifyService.build()

export default {
  components: {},
  data() {
    return {
      AllCertificates: [],
      firstPage: 1,
      lastPage: 1,
      currentPage: 1,
      AllCertificatesRecentes: [],
      selectedCertify: {
        id: 24,
        site_id: 1,
        course_id: 24,
        media_id: 5,
        created_by: 1,
        updated_by: 1,
        deleted_by: null,
        created_at: "2021-03-05T18:41:31.000000Z",
        updated_at: "2021-03-05T18:41:31.000000Z",
        media: {
          id: 5,
          site_id: 1,
          title: "pexels-karolina-grabowska-4498362.jpg",
          path: "media/1/pexels-karolina-grabowska-4498362.jpg",
          mime: "image/jpeg",
          storageAdapter: "Storage",
          disk: "do_space",
          protected: 0,
          connection_id: null,
          created_by: 1,
          updated_by: 1,
          created_at: "2021-03-05T18:41:25.000000Z",
          updated_at: "2021-03-05T18:41:25.000000Z",
          cdn_url:
            "https://nyc3.digitaloceanspaces.com/club.greenn/media/1/pexels-karolina-grabowska-4498362.jpg",
          media_meta: [],
        },
      },
      totalCertificates: 0,
      client: {
        width: 0
      },
      loading: false
    }
  },
  computed: {
    isMobile() {
      return this.client.width <= 576
    }
  },
  created() {
    window.addEventListener("resize", this.handleResize)
    this.handleResize()
  },
  methods: {
    handleResize() {
      this.client.width = window.innerWidth
    },
    downloadCertify() {},
    nextPage(action) {
      if (action === "prev") {
        if (this.currentPage !== 1) {
          this.currentPage = this.currentPage - 1
          this.getCertify()
        }
      } else if (action === "next") {
        if (this.currentPage !== this.lastPage) {
          this.currentPage = this.currentPage + 1
          this.getCertify()
        }
      }
    },
    getCertify() {
      this.loading = true
      serviceCertificate
        .read("?page=" + this.currentPage)
        .then(resp => {
          this.AllCertificates = resp.data
          this.lastPage = resp.last_page
          this.totalCertificates = resp.total
        })
        .catch(err => console.error(err))
        .finally(() => { this.loading = false })
    },
    getCertifyUniq(courseId) {
      this.loading = true;
      serviceCourse
        .read(courseId + "/certificate/me")
        .then(resp => {
          this.selectedCertify = resp
          this.$root.$emit("openCertifyToModal", resp)
        })
        .catch((err) => console.log(err))
        .finally(() => { this.loading = false })
    },
    getCertifyRecente() {
      this.loading = true;
      serviceCertificate
        .read("?page=1")
        .then(resp => {
          let array = resp.data
          let array2 = []
          for (let i = 0; i < array.length; i++) {
            const element = array[i]
            if (i < 3) {
              array2.push(element)
            }
          }
          this.AllCertificatesRecentes = array2
        })
        .catch(err => console.log(err))
        .finally(() => { this.loading = false })
    },
  },
  mounted() {
    this.getCertify()
    this.getCertifyRecente()
  }
}
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

.spaceTitleDown {
  width: 100%;
  p {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    color: #333333;
  }
}

.spaceImgDown {
  width: 100%;
  height: 40em;
  overflow: hidden;
  border-radius: 5px;
  img {
    width: 100%;
  }
}

.spaceBtnDown {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.btnDownload {
  background: #00e4a0;
  border: 1px solid #00e4a0;
  box-sizing: border-box;
  box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
  border-radius: 5px;
  width: 251.29px;
  height: 55px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  letter-spacing: 0.2px;
  color: #ffffff;
}

.paginacao {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin-top: 20px;
  margin-bottom: 60px;
  margin-left: auto;
  margin-right: auto;
  .page {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.2px;
    color: #333333;
    padding: 10px;
    cursor: pointer;
  }
  .page:hover {
    color: #00e4a0;
  }
}

.course {
  cursor: pointer;
}
.iconBtnNext {
  position: relative;
  top: 2px;
}
.router-curso {
  text-decoration: none;
}
.pointerTitle {
  position: relative;
  top: -10px;
  margin-right: 10px;
}
.footerCertify {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  padding: 2px;
  svg {
    .a,
    .b {
      fill: var(--maincolor) !important;
    }
    .c {
      stroke: var(--maincolor);
    }
  }
  .title {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: var(--fontcolor);
  }
  .flex {
    display: flex;
  }
  .data {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    color: var(--maincolor);
    padding-left: 4px;
    padding-top: 3px;
  }
}
.row.d-flex.spaceCourse {
  flex-wrap: inherit;
  overflow: hidden;
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.spaceCertifyBox {
  margin-bottom: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.spaceCertifyBoxMobile {
  margin-bottom: 30px;
  display: grid;
  grid-template-columns: 1fr;
}
.itemBox {
  margin-bottom: 25px;
}
.flex {
  display: flex;
  svg {
    position: relative;
    top: 5px;
    margin-right: 20px;
  }
}
.titleCategoria {
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  color: var(--fontcolor);
}
.titleCategoria::before {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 2px solid var(--maincolor);
  background: var(--maincolortrans);
  display: block;
  margin-right: 20px;
}
.spaceCourcex {
  margin-bottom: 50px;
}
.box {
  width: 100%;
  height: 194.03px;
  border-radius: 5px;
  margin-right: 5px;
  background: #333;
  display: table;
  margin-bottom: 15px;
}
.spaceCertifyMobile {
  width: 100%;
  padding-left: 20px;
  padding-top: 45px;
  .spaceProp {
    margin-top: 50px;
  }
  .box-certify {
    height: 175px;
    width: 100%;
    background: #333;
    padding-left: 40px;
    padding-right: 40px;
    border-radius: 5px;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
  }
  .box-certifyRecente {
    height: 190px;
    width: 100%;
    background: #333;
    padding-left: 40px;
    padding-right: 40px;
    border-radius: 5px;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
  }
  .flex {
    display: flex;
    margin-left: 48px;
  }
  .lineDiv {
    width: 90%;
    height: 0.5px;
    margin-left: auto;
    margin-right: auto;
    background: #ededf0;
    margin-top: 60px;
    margin-bottom: 60px;
  }
  .Pointer {
    cursor: pointer;
  }
  .flexHeader {
    display: flex;
    justify-content: space-between;
    .title {
      font-family: Montserrat;
      font-style: normal;
      font-weight: normal;
      color: #81858e;
      font-size: 13px;
    }
    .btn-NewCourse {
      padding: 20px 23px;
      background: var(--maincolor);
      border: none;
      box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
      border-radius: 5px;
      width: 255px;
      font-size: 15px;
      color: #fff;
      font-weight: 600;
      font-family: Montserrat;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .btn-NewCourse img {
      margin-left: 20px;
    }
    .titleAll {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      color: var(--fontcolor);
    }
  }
}
.spaceCertify {
  width: 100%;
  padding-left: 50px;
  padding-top: 70px;
  .spaceProp {
    margin-top: 50px;
  }
  .box-certify {
    height: 175px;
    width: 100%;
    background: #333;
    padding-left: 40px;
    padding-right: 40px;
    border-radius: 5px;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
  }
  .box-certifyRecente {
    height: 190px;
    width: 100%;
    background: #333;
    padding-left: 40px;
    padding-right: 40px;
    border-radius: 5px;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
  }
  .flex {
    display: flex;
    margin-left: 48px;
  }
  .lineDiv {
    width: 90%;
    height: 0.5px;
    margin-left: auto;
    margin-right: auto;
    background: #ededf0;
    margin-top: 60px;
    margin-bottom: 60px;
  }
  .Pointer {
    cursor: pointer;
  }
  .flexHeader {
    display: flex;
    justify-content: space-between;
    .title {
      font-family: Montserrat;
      font-style: normal;
      font-weight: normal;
      color: #81858e;
      font-size: 13px;
    }
    .btn-NewCourse {
      padding: 20px 23px;
      background: var(--maincolor);
      border: none;
      box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
      border-radius: 5px;
      width: 255px;
      font-size: 15px;
      color: #fff;
      font-weight: 600;
      font-family: Montserrat;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .btn-NewCourse img {
      margin-left: 20px;
    }
    .titleAll {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      color: var(--fontcolor);
    }
  }
}
</style>
