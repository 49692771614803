<template>
  <b-row
    data-anima="top"
    class="container-box padding-clear col-sm-12 col-md-12 col-12"
  >
    <ModalCertificate></ModalCertificate>

    <b-col>
      <div class="flexHeader margin-bottom">
        <div class="titleAll">
          {{ $t("certificates_component.title_v2") }}
        </div>
        <span class="title-desc">{{
          $t("certificates_component.title_desc")
        }}</span>
      </div>

      <div v-if="!loading" data-anima="top">
        <b-row class="align-margin margin-fix spaceProp">
          <b-col
            cols="12"
            md="6"
            sm="12"
            class="certify-box"
            v-for="certificado in AllCertificates"
            :key="certificado.id"
            @click="getCertifyUniq(certificado.course_id)"
          >
            <b-col cols="12" class="card-item">
              <b-col>
                <div class="card-col">
                  <div class="card-data">
                    <h1 class="card-title">
                      {{ certificado.course.title }}
                    </h1>
                    <span class="card-desc">
                      {{ $t("certificates_component.conclusion") }}
                      {{ certificado.created_at | moment("DD/MM/YYYY") }}
                    </span>
                  </div>
                  <b-avatar
                    class="card-avatar"
                    :size="isMobile ? '2rem' : '3rem'"
                    variant="info"
                    :src="certificado.media.cdn_url"
                  ></b-avatar>
                </div>
              </b-col>
            </b-col>
          </b-col>
        </b-row>
        <div v-if="totalCertificates === 0" class="spaceProp">
          <div class="flex">
            <p class="titleCategoria">
              {{ $t("certificates_component.nenhum") }}
            </p>
          </div>
        </div>
      </div>
      <!-- Loading -->
      <div v-if="loading" class="d-flex justify-content-center mt-5">
        <b-spinner label="Loading..."></b-spinner>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import CourseService from "@/services/resources/CourseService";
import CertifyService from "@/services/resources/CertifyService";
import ModalCertificate from "@/components/V2/modals/ModalCertificate";

const serviceCourse = CourseService.build();
const serviceCertificate = CertifyService.build();

export default {
  name: "CertificatesComponentV2",
  components: { ModalCertificate },
  data() {
    return {
      AllCertificates: [],
      firstPage: 1,
      lastPage: 1,
      currentPage: 1,
      AllCertificatesRecentes: [],
      selectedCertify: {
        id: 24,
        site_id: 1,
        course_id: 24,
        media_id: 5,
        created_by: 1,
        updated_by: 1,
        deleted_by: null,
        created_at: "2021-03-05T18:41:31.000000Z",
        updated_at: "2021-03-05T18:41:31.000000Z",
        media: {
          id: 5,
          site_id: 1,
          title: "pexels-karolina-grabowska-4498362.jpg",
          path: "media/1/pexels-karolina-grabowska-4498362.jpg",
          mime: "image/jpeg",
          storageAdapter: "Storage",
          disk: "do_space",
          protected: 0,
          connection_id: null,
          created_by: 1,
          updated_by: 1,
          created_at: "2021-03-05T18:41:25.000000Z",
          updated_at: "2021-03-05T18:41:25.000000Z",
          cdn_url:
            "https://nyc3.digitaloceanspaces.com/club.greenn/media/1/pexels-karolina-grabowska-4498362.jpg",
          media_meta: [],
        },
      },
      totalCertificates: 0,
      client: {
        width: 0,
      },
      loading: false,
    };
  },
  computed: {
    isMobile() {
      return this.client.width <= 576;
    },
    theme() {
      return this.$store.state.globalSettings.theme;
    },
    clubVersion() {
      return this.$store.state.globalSettings.clubVersion;
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  methods: {
    handleResize() {
      this.client.width = window.innerWidth;
    },
    downloadCertify() {},
    nextPage(action) {
      if (action === "prev") {
        if (this.currentPage !== 1) {
          this.currentPage = this.currentPage - 1;
          this.getCertify();
        }
      } else if (action === "next") {
        if (this.currentPage !== this.lastPage) {
          this.currentPage = this.currentPage + 1;
          this.getCertify();
        }
      }
    },
    getCertify() {
      this.loading = true;
      serviceCertificate
        .read("?page=" + this.currentPage)
        .then((resp) => {
          this.AllCertificates = resp.data;
          this.lastPage = resp.last_page;
          this.totalCertificates = resp.total;
        })
        .catch((err) => console.error(err))
        .finally(() => {
          this.loading = false;
        });
    },
    getCertifyUniq(courseId) {
      this.loading = true;
      serviceCourse
        .read(courseId + "/certificate/me")
        .then((resp) => {
          this.selectedCertify = resp;
          this.$root.$emit("openCertifyToModalV2", resp);
        })
        .catch((err) => console.log(err))
        .finally(() => {
          this.loading = false;
        });
    },
    getCertifyRecente() {
      this.loading = true;
      serviceCertificate
        .read("?page=1")
        .then((resp) => {
          let array = resp.data;
          let array2 = [];
          for (let i = 0; i < array.length; i++) {
            const element = array[i];
            if (i < 3) {
              array2.push(element);
            }
          }
          this.AllCertificatesRecentes = array2;
        })
        .catch((err) => console.log(err))
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.getCertify();
    this.getCertifyRecente();
  },
};
</script>

<style lang="scss" scoped>
.margin-fix {
  margin-right: 0px;
  margin-left: 25px;
}

.padding-clear {
  padding: 0 !important;
}
.margin-bottom {
  margin-bottom: 56px;
  margin-left: 32px;
}
.container-box {
  margin-top: 128px;
  display: flex;
  overflow: hidden;
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.flexHeader {
  display: flex;
  flex-wrap: wrap;
}
.titleAll {
  font-family: "Montserrat Alternates";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  align-items: center;
  color: var(--fontcolor-v2);
  flex-basis: 100%;
}

.card-data {
  padding: 1px 0px 0px 10px;
}
.card-title {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: var(--fontcolor-v2);
}

.card-avatar {
  margin-top: 2px;
  margin-right: 10px;
  max-width: max-content !important;
}
.card-desc {
  margin-top: -10px;
  display: block;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #9a9a9a;
}

.title-desc {
  color: #9a9a9a;
  max-width: 540px;
  display: block;
  margin-top: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;
  flex-basis: 100%;
}
.spaceTitleDown {
  width: 100%;
  p {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    font-style: normal;
    font-weight: 800;
    color: #333333;
    background: var(--maincolor);
  }
}

.spaceImgDown {
  width: 100%;
  height: 40em;
  overflow: hidden;
  border-radius: 5px;
  img {
    width: 100%;
  }
}

.spaceBtnDown {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.btnDownload {
  background: #00e4a0;
  border: 1px solid #00e4a0;
  box-sizing: border-box;
  box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
  border-radius: 5px;
  width: 251.29px;
  height: 55px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  letter-spacing: 0.2px;
  color: #ffffff;
}

.paginacao {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin-top: 20px;
  margin-bottom: 60px;
  margin-left: auto;
  margin-right: auto;
  .page {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.2px;
    color: #333333;
    padding: 10px;
    cursor: pointer;
  }
  .page:hover {
    color: #00e4a0;
  }
}

.course {
  cursor: pointer;
}
.iconBtnNext {
  position: relative;
  top: 2px;
}
.router-curso {
  text-decoration: none;
}
.pointerTitle {
  position: relative;
  top: -10px;
  margin-right: 10px;
}
.footerCertify {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  padding: 2px;
  svg {
    .a,
    .b {
      fill: var(--maincolor) !important;
    }
    .c {
      stroke: var(--maincolor);
    }
  }
  .title {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: var(--fontcolor);
  }
  .flex {
    display: flex;
  }
  .data {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    color: var(--maincolor);
    padding-left: 4px;
    padding-top: 3px;
  }
}
.row.d-flex.spaceCourse {
  flex-wrap: inherit;
  overflow: hidden;
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.spaceCertifyBox {
  margin-bottom: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.spaceCertifyBoxMobile {
  margin-bottom: 30px;
  display: grid;
  grid-template-columns: 1fr;
}
.itemBox {
  margin-bottom: 25px;
}
.flex {
  display: flex;
  svg {
    position: relative;
    top: 5px;
    margin-right: 20px;
  }
}
.titleCategoria {
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  color: var(--fontcolor);
}
.titleCategoria::before {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 2px solid var(--maincolor);
  background: var(--maincolortrans);
  display: block;
  margin-right: 20px;
}
.spaceCourcex {
  margin-bottom: 50px;
}
.box {
  width: 100%;
  height: 194.03px;
  border-radius: 5px;
  margin-right: 5px;
  background: #333;
  display: table;
  margin-bottom: 15px;
}
.spaceCertifyMobile {
  width: 100%;
  padding-left: 20px;
  padding-top: 45px;
  .spaceProp {
    margin-top: 10px;
  }
  .box-certify {
    height: 175px;
    width: 100%;
    background: #333;
    padding-left: 40px;
    padding-right: 40px;
    border-radius: 5px;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
  }
  .box-certifyRecente {
    height: 190px;
    width: 100%;
    background: #333;
    padding-left: 40px;
    padding-right: 40px;
    border-radius: 5px;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
  }
  .flex {
    display: flex;
    margin-left: 48px;
  }
  .lineDiv {
    width: 90%;
    height: 0.5px;
    margin-left: auto;
    margin-right: auto;
    background: #ededf0;
    margin-top: 60px;
    margin-bottom: 60px;
  }
  .Pointer {
    cursor: pointer;
  }
  .flexHeader {
    .title {
      font-family: Montserrat;
      font-style: normal;
      font-weight: normal;
      color: #81858e;
      font-size: 13px;
    }
    .btn-NewCourse {
      padding: 20px 23px;
      background: var(--maincolor);
      border: none;
      box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
      border-radius: 5px;
      width: 255px;
      font-size: 15px;
      color: #fff;
      font-weight: 600;
      font-family: Montserrat;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .btn-NewCourse img {
      margin-left: 20px;
    }
    .titleAll {
      font-family: "Montserrat Alternates";
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
      display: flex;
      align-items: center;
      color: var(--fontcolor);
    }
  }
}
.spaceCertify {
  width: 100%;
  padding-left: 50px;
  padding-top: 70px;
  padding-right: 50px;
  .spaceProp {
    margin-top: 50px;
  }
  .box-certify {
    height: 175px;
    width: 100%;
    background: #333;
    padding-left: 40px;
    padding-right: 40px;
    border-radius: 5px;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
  }
  .box-certifyRecente {
    height: 190px;
    width: 100%;
    background: #333;
    padding-left: 40px;
    padding-right: 40px;
    border-radius: 5px;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
  }
  .flex {
    display: flex;
    margin-left: 48px;
  }
  .lineDiv {
    width: 90%;
    height: 0.5px;
    margin-left: auto;
    margin-right: auto;
    background: #ededf0;
    margin-top: 60px;
    margin-bottom: 60px;
  }
  .Pointer {
    cursor: pointer;
  }
  .flexHeader {
    .title {
      font-family: Montserrat;
      font-style: normal;
      font-weight: normal;
      color: #81858e;
      font-size: 13px;
    }
    .btn-NewCourse {
      padding: 20px 23px;
      background: var(--maincolor);
      border: none;
      box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
      border-radius: 5px;
      width: 255px;
      font-size: 15px;
      color: #fff;
      font-weight: 600;
      font-family: Montserrat;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .btn-NewCourse img {
      margin-left: 20px;
    }
    .titleAll {
      font-family: "Montserrat Alternates";
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
      display: flex;
      align-items: center;
      color: var(--fontcolor);
    }
  }
}

.certify-box {
  margin-bottom: 20px;
  padding-right: 6px;
  padding-left: 6px;
  cursor: pointer;
}

.card-item {
  padding: 26px;
  border-radius: 25px;
  color: var(--fontcolor-v2);
  background: var(--background2-v2);
}
.card-col {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  justify-content: space-between;
}
@media screen and (max-width: 200px) {
  .certify-box {
    padding-right: 34px;
  }
}
@media screen and (max-width: 580px) {
  .margin-bottom {
    margin-left: 0px;
  }
  .align-margin {
    margin-left: 10px;
  }
  .card-item {
    padding: 3px 15px;
    border-radius: 10px;
  }
  .card-data {
    margin-left: -16px;
  }
  .card-avatar {
    margin-top: 6px;
    margin-right: -10px;
  }
  .card-col {
    padding: 10px !important;
    border-radius: 20px !important;
  }
  .card-title {
    font-size: 0.8rem !important;
  }
  .card-desc {
    font-size: 0.7rem;
  }

  .titleAll {
    margin-left: 25px !important;
  }
  .title-desc {
    max-width: 350px !important;
    margin-left: 25px;
  }
}
</style>
